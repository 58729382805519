import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { ParamsService } from '../../../services/params.service';
import { LanguageService } from '@backend/webapp/services/language.service';
import { ReportService } from '@backend/webapp/shared/report/report.service';
import { ReportType } from '@backend/webapp/shared/report/report-type.enum';
import { WorkflowGuidesService } from '../../workflowguides.service';
import { Router } from '@angular/router';
import { ConfirmationService } from '@backend/webapp/shared/confirmation/confirmation.service';
import { SpinnerService } from '@backend/webapp/spinner/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class PhaseVersionService {
  private phasenVersionSubject: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >([]);

  public get phaseVersions(): Observable<any[]> {
    return this.phasenVersionSubject.asObservable();
  }

  public constructor(
    private readonly http: HttpClient,
    private readonly paramsService: ParamsService,
    private readonly languageService: LanguageService,
    private reportService: ReportService,
    private readonly linksService: WorkflowGuidesService,
    public router: Router,
    private confirmationService: ConfirmationService,
    private readonly spinner: SpinnerService
  ) { }

  public getPhaseVersions(): Observable<any[]> {
    this.phasenVersionSubject.next([]);
    this.loadPhaseVersions();
    return this.phasenVersionSubject.asObservable();
  }

  public async createPhaseVersion(
    phaseVersion: any,
    thumbnail: File
  ): Promise<any> {
    let query = { copyVersion: phaseVersion.copyFrom },
      showReport = false;
    if (phaseVersion.copyFrom > 0) {
      showReport = true;
    }
    this.paramsService.isLoading = true;
    if (showReport) {
      this.reportService.progress(null, null, null, ReportType.Progress);
    }
    const result = await lastValueFrom(
      this.http.post<any>(
        '/api/workflowguides/phases',
        this.getFormData(phaseVersion, thumbnail),
        { params: query }
      )
    );
    this.paramsService.isLoading = false;
    this.reportService.close();
    if (showReport && result && result.report) {
      this.reportService.confirm(
        'Phase Version: ' + phaseVersion.versionDescription + ' Copied!',
        result.report,
        result.errors,
        ReportType.Success
      );
    }
    return result;
  }

  public async deletePhaseVersion(id: number) {
    this.paramsService.isLoading = true;
    try {
      await lastValueFrom(this.http.delete('/api/workflowguides/phases/' + id));
      return true;
    } catch (e) {
      console.log(e);
      return false;
    } finally {
      this.paramsService.isLoading = false;
    }
  }

  private getFormData(settings: any, image: File): FormData {
    const formData = new FormData();
    Object.keys(settings).forEach((k) => {
      if (settings[k] !== null && settings[k] !== 'Do not copy') {
        formData.append(k, settings[k].toString());
      }
    });

    if (image) formData.append('image', image, image.name);

    return formData;
  }

  public async editPhaseVersion(
    id: number,
    name: string,
    description: string,
    thumbnail: File,
    langKey
  ): Promise<any> {
    this.spinner.showSpinner();
    this.paramsService.isLoading = true;
    const result = await lastValueFrom(
      this.http.put(
        '/api/workflowguides/phases/' + id,
        this.getFormData(
          { name: name, langKey: langKey, description: description },
          thumbnail
        )
      )
    );
    this.paramsService.isLoading = false;
    this.spinner.hideSpinner();
    return result;
  }

  public async getPhaseVersion(id: number = this.paramsService.phaseVersionId) {
    this.paramsService.isLoading = true;
    const result = await lastValueFrom(
      this.http.get('/api/workflowguides/phases/' + id)
    );
    this.paramsService.isLoading = false;
    return result;
  }

  public async loadPhaseVersions() {
    this.paramsService.isLoading = true;
    this.phasenVersionSubject.next(
      await lastValueFrom(this.http.get<any[]>('/api/workflowguides/phases/'))
    );
    this.paramsService.isLoading = false;
    await this.linksService.loadPhaseLinks();
    await this.linksService.loadWfLinks();
  }

  public async editDescription(id: any, description: string): Promise<any> {
    return await lastValueFrom(
      this.http.put(
        '/api/workflowguides/phases/' +
          this.paramsService.phaseWrapperId +
          '/versions/' +
          id +
          '/description/',
        {
          langKey: this.languageService.guiLanguageCode,
          content: description,
        }
      )
    );
  }

  public async editName(id: any, name: string): Promise<any> {
    return await lastValueFrom(
      this.http.put(
        '/api/workflowguides/phases/' +
          this.paramsService.phaseWrapperId +
          '/versions/' +
          id +
          '/name/',
        {
          langKey: this.languageService.guiLanguageCode,
          content: name,
        }
      )
    );
  }

  async getVersionsOfCurrentPhase() {
    if (this.paramsService.phaseWrapperId === null) {
      return null;
    }
    return await lastValueFrom(
      this.http.get<any[]>(
        '/api/workflowguides/phases/' +
          this.paramsService.phaseWrapperId +
          '/versions/'
      )
    );
  }
}
