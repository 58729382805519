import { Component, OnDestroy, OnInit } from '@angular/core';
import { KeyActivityService } from '../key-activity.service';
import { Router } from '@angular/router';
import { LanguageService } from '../../../../../services/language.service';
import { ParamsService } from '../../../../../services/params.service';
import { WorkflowGuidesService } from '@backend/webapp/workflowguides/workflowguides.service';
import { Constants } from '@backend/interfaces';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { GetLocaleTextPipe } from '@backend/webapp/get-locale-text.pipe';
import { pairwise } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { WorkflowService } from '@backend/webapp/workflowguides/workflows/workflow.service';
import ShortUniqueId from 'short-unique-id';

const uidGen = new ShortUniqueId();

@Component({
  selector: 'backend-edit-key-activity',
  templateUrl: './edit-key-activity.component.html',
  styleUrls: ['./edit-key-activity.component.scss'],
})
export class EditKeyActivityComponent implements OnInit, OnDestroy {
  public isEditMode = false;

  public id: number;
  public key: string;
  public title: string;
  public section = 'steps';
  public isKeyActivityNameValid = true;
  public keyActivities: any[];
  public showWarningModal = false;
  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  titleTrans: any[];
  editKeyActivity: any;
  paramSubscription: Subscription;
  languageSubscription: Subscription;

  public constructor(
    private readonly router: Router,
    private readonly keyActivityService: KeyActivityService,
    private readonly paramsService: ParamsService,
    public readonly languageService: LanguageService,
    public readonly linksService: WorkflowGuidesService,
    public readonly localization: LocalizationService,
    public readonly workflowService: WorkflowService
  ) {
    this.section = 'steps';
  }

  public async ngOnInit(): Promise<void> {
    this.keyActivities = await this.keyActivityService.getCurrentKeyActivities();
    this.resetFields();
    this.updateFields();
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
          this.resetFields();
          await this.updateFields();
        }
      }
    );
    this.paramSubscription = this.paramsService.keyActivityId$
      .pipe(pairwise())
      .subscribe(async ([previous, current]) => {
        if (previous != null && previous !== current && this.router.isActive) {
          this.resetFields();
          await this.updateFields();
        }
      });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  private async updateFields(): Promise<void> {
    const keyActivity = (await this.keyActivityService.getKeyActivity()) as any;
    this.editKeyActivity = keyActivity;
    if (keyActivity) {
      this.section = 'steps';
      this.key = keyActivity.key;
      this.title = this.languageService.getTranslationByKey(
        keyActivity.title,
        this.globalLanguage.key
      );
      this.titleTrans = new GetLocaleTextPipe(this.localization).transform(
        keyActivity.title,
        this.globalLanguage.key
      );
      this.id = keyActivity.id;
      this.isEditMode = true;
    } else {
      this.section = 'base';
      this.resetFields();
      this.isEditMode = false;
    }
  }

  public async checkForValidName(keyActivityTitle: string): Promise<void>{
    this.title = keyActivityTitle.trim();
    if (this.isEditMode) {
      const editKeyActivityTitle = new GetLocaleTextPipe(
        this.localization
      ).transform(this.editKeyActivity.title, this.globalLanguage.key);
      if (
        this.title?.toLowerCase() === editKeyActivityTitle.trim().toLowerCase()
      ) {
        this.isKeyActivityNameValid = true;
        return;
      }
    }

    for (let keyActivity of this.keyActivities) {
      const keyActivityTitle = new GetLocaleTextPipe(this.localization).transform(
        keyActivity.title,
        this.globalLanguage.key
      );

      if (keyActivityTitle.trim()?.toLowerCase() === this.title?.toLowerCase()) {
        this.isKeyActivityNameValid = false;
        break;
      } else {
        this.isKeyActivityNameValid = true;
      }
    }
  }

  private resetFields(): void {
    this.key = '';
    this.title = '';
    this.id = null;
  }

  public async save() {
    let result: any;
    if (!this.isEditMode) {
      result = await this.keyActivityService.createKeyActivity(uidGen.randomUUID(8),this.keyActivities.length+1);
    } else {
      result = await this.keyActivityService.editKeyActivity(this.id, this.key);
    }

    if (result) {
      if (
        result.title == null ||
        this.languageService.getTranslationByKey(
          result.title,
          this.globalLanguage.key
        ) !== this.title
      ) {
        await this.keyActivityService.editTitle(result.id, this.title);
      }

      this.goBack();
      this.linksService.loadPhaseLinks();
      this.linksService.loadWfLinks();
    }
  }

  public async cancel() {
    this.showWarningModal = false;
    await this.goBack();
  }

  public async goBack() {
    if (this.router.url.indexOf('/workflows') !== -1) {
      await this.router.navigate([
        'workflowguides',
        'workflows',
        this.paramsService.workflowId,
        'versions',
        this.paramsService.workflowVersionId,
        'phases',
        this.paramsService.phaseVersionId
      ]);
    } else {
      await this.router.navigate([
        'workflowguides',
        'phases',
        this.paramsService.phaseWrapperId,
        'versions',
        this.paramsService.phaseVersionId,
      ]);
    }
  }
}
